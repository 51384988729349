import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { TabletUp, useIsMobile } from 'utils/responsiveHelpers';
import { actions, crmService } from 'wintrado-api';
import styles from './AccountStatement.module.scss';
import Tabs from 'components/Tabs/Tabs';
import BalanceBar from 'components/BalanceBar/BalanceBar';
import { formatDateTime, getNowAndMinusMonth, priceChange, transactionsSum } from '../../../../helpers';
import AccountStatementTable from './AccountStatementTable';
import { formatCurrency } from 'utils/currency';
import PositionsFooter from './PositionsFooter';
import PositionCard from './PositionCard';
import TransactionCard from './TransactionCard';
import StatementFilters from './StatementFilters';
import Button from 'components/Button/Button';
import { useAppDispatch } from '../../../../storeHooks';
import trendingUp from '../../../../assets/icons/trending-up.svg?url';
import trendingDown from '../../../../assets/icons/trending-down.svg?url';
import Spacer from 'components/Spacer/Spacer';
import config from 'config';
import InstrumentIconName from 'components/InstrumentIconName/InstrumentIconName';
const _t = require('counterpart');
const PageAccountStatement = ({ accountCurrency, wallet }) => {
    var _a, _b;
    const { start, end } = getNowAndMinusMonth();
    const [activeTab, setActiveTab] = useState('positions');
    const [selectedWalletUsername, setSelectedWalletUsername] = useState(wallet.username);
    const [mobileFiltersShow, setMobileFiltersShow] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: start,
        endDate: end,
    });
    const baseURL = config.cashier.baseURL;
    const getPositionsExportUrl = (wallet, startDate, endDate, token) => {
        return `${baseURL}/api/users/self/positions/${wallet}/export?startDate=${startDate}&endDate=${endDate}&token=${token}`;
    };
    const getTransactionsExportUrl = (wallet, startDate, endDate, token) => {
        return `${baseURL}/api/users/self/wallets/${wallet}/transactions/export?startDate=${startDate}&endDate=${endDate}&token=${token}`;
    };
    const dispatch = useAppDispatch();
    const [fullDateRange, setFullDateRange] = useState(dateRange);
    const walletTransactionsQuery = useQuery({
        queryKey: ['wallet-transactions', selectedWalletUsername, fullDateRange],
        queryFn: () => crmService.walletTransactions(selectedWalletUsername, fullDateRange.startDate, fullDateRange.endDate),
        onError: (error) => {
            dispatch(actions.displayError('Failed to fetch transactions', error.message));
        },
    });
    const walletPositionsQuery = useQuery({
        queryKey: ['wallet-positions', selectedWalletUsername, fullDateRange],
        queryFn: () => crmService.positionsByWallet(selectedWalletUsername, fullDateRange.startDate, fullDateRange.endDate),
        onError: (error) => {
            dispatch(actions.displayError('Failed to fetch positions', error.message));
        },
    });
    useEffect(() => {
        if (dateRange.startDate !== null && dateRange.endDate !== null) {
            setFullDateRange(dateRange);
        }
    }, [dateRange]);
    const transactions = (_a = walletTransactionsQuery.data) !== null && _a !== void 0 ? _a : [];
    const positions = (_b = walletPositionsQuery.data) !== null && _b !== void 0 ? _b : [];
    const transactionsColumns = useMemo(() => [
        { key: 'id', label: '#' },
        { key: 'createdAt', label: _t('account_statement.created-at') },
        { key: 'type', label: _t('account_statement.type') },
        { key: 'comment', label: _t('account_statement.description') },
        { key: 'amount', label: _t('account_statement.amount') },
    ], []);
    const transactionCellOverrides = useMemo(() => ({
        id: ({ id }) => _jsxs("td", { children: ["#", id] }),
        createdAt: ({ createdAt }) => _jsx("td", { children: formatDateTime(createdAt) }),
        amount: ({ amount }) => _jsx("td", { children: formatCurrency(Number(amount), accountCurrency) }),
    }), [accountCurrency]);
    const positionsColumns = useMemo(() => [
        { key: 'id', label: _t('main.position') },
        { key: 'side', label: _t('account_statement.type') },
        { key: 'symbol', label: _t('account_statement.ticker') },
        { key: 'closePrice', label: _t('account_statement.close-price') },
        { key: 'openedAt', label: _t('account_statement.opened-at') },
        { key: 'closedAt', label: _t('account_statement.closed-at') },
        { key: 'profitLoss', label: 'P&L' },
        { key: 'return', label: _t('account_statement.return') },
    ], []);
    const cellOverrides = useMemo(() => ({
        id: ({ id }) => _jsxs("td", { children: ["#", id] }),
        side: ({ side }) => (_jsxs("td", { children: [_jsx("span", { children: _t(`account_statement.${side}`) }), _jsx(Spacer, { x: "extraExtraSmall" }), _jsx("img", { alt: "side", width: "16", src: side === 'buy' ? trendingUp : trendingDown })] })),
        symbol: ({ symbol, iconUrl }) => (_jsx("td", { children: _jsx(InstrumentIconName, { instrument: {
                    iconUrl,
                    symbol,
                } }) })),
        openedAt: ({ openedAt }) => _jsx("td", { children: formatDateTime(openedAt) }),
        closedAt: ({ closedAt }) => _jsx("td", { children: formatDateTime(closedAt) }),
        profitLoss: ({ openPrice, closePrice }) => (_jsxs("td", { children: [priceChange(Number(openPrice), Number(closePrice)).toFixed(2), "%"] })),
        return: ({ closedPLInAccountCurrency }) => _jsx("td", { children: formatCurrency(Number(closedPLInAccountCurrency), accountCurrency) }),
    }), [accountCurrency]);
    const onWalletChanged = (username) => {
        setSelectedWalletUsername(username);
    };
    const showMobileFilters = () => {
        setMobileFiltersShow(true);
    };
    const hideMobileFilters = () => {
        setMobileFiltersShow(false);
    };
    const onDateRangeChanged = (range) => {
        setDateRange(range);
    };
    const sumTradeChange = transactions.reduce((a, t) => a + priceChange(t.openPrice, t.closePrice), 0);
    const totalPL = positions.reduce((a, c) => a + Number(c.closedPL), 0);
    const sumOfTrans = transactions.reduce((a, c) => transactionsSum(a, c.amount, c.type), 0);
    const isMobile = useIsMobile();
    const dataCount = activeTab === 'positions' ? positions.length : transactions.length;
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(TabletUp, { children: _jsx(BalanceBar, {}) }), _jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx(StatementFilters, { currentWallet: selectedWalletUsername, onWalletSelected: onWalletChanged, onOutsideClick: hideMobileFilters, show: mobileFiltersShow, dateRange: dateRange, onRangeChanged: onDateRangeChanged, getExportUrl: activeTab === 'positions' ? getPositionsExportUrl : getTransactionsExportUrl, dataCount: dataCount }), _jsx(Tabs, { value: activeTab, onValueChange: setActiveTab, listClassName: styles.header, tabsListSuffix: _jsx(Button, Object.assign({ type: 'submit', variant: "primary", className: styles.tabsSuffix, onClick: showMobileFilters }, { children: _t('main.filter') })), content: [
                            {
                                value: 'positions',
                                label: _t('main.positions'),
                                content: (_jsx("div", Object.assign({ className: styles.tabContentWrapper }, { children: _jsx(AccountStatementTable, { columns: positionsColumns, cellOverrides: cellOverrides, rowsKey: "id", data: positions.sort((a, b) => b.id - a.id), isLoading: walletPositionsQuery.isLoading, card: PositionCard, useCards: isMobile }) }))),
                            },
                            {
                                value: 'transactions',
                                label: _t('account_statement.deposit-withdrawal'),
                                content: (_jsx("div", Object.assign({ className: styles.tabContentWrapper }, { children: _jsx(AccountStatementTable, { columns: transactionsColumns, cellOverrides: transactionCellOverrides, rowsKey: "id", data: transactions, isLoading: walletTransactionsQuery.isLoading, card: TransactionCard, useCards: isMobile }) }))),
                            },
                        ] })] })), _jsxs("div", Object.assign({ className: styles.footer }, { children: [activeTab === 'positions' && (_jsx(PositionsFooter, { profitLossPercentage: sumTradeChange.toFixed(2), profitLoss: totalPL.toFixed(4), currency: wallet.currency })), activeTab == 'transactions' && (_jsxs(_Fragment, { children: [_jsx("span", { children: _t('account_statement.total') }), _jsx("span", { children: formatCurrency(sumOfTrans, wallet.currency) })] }))] }))] })));
};
const mapStateToProps = (state) => ({
    accountCurrency: state.user.accountCurrency,
    wallet: state.user.wallet,
});
export default connect(mapStateToProps)(PageAccountStatement);
