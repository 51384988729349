var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styles from './PushToggle.module.scss';
import _t from 'counterpart';
import ToggleGroup from 'components/ToggleGroup/ToggleGroup';
import { deleteSubscription, getSubscription, getVapidKey, pushSubscribe } from 'wintrado-api/src/services/crmService';
import { useMutation } from '@tanstack/react-query';
import { extractErrorMessage, urlBase64ToUint8Array } from '../../helpers';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import { useToastContext } from '../../hooks';
const PushToggle = () => {
    const [value, setValue] = useState('off');
    const [loading, setLoading] = useState();
    const addToast = useToastContext();
    const pushSubcribeMutation = useMutation({
        mutationFn: (subsciption) => pushSubscribe(subsciption, 'trading-pro'),
        onSuccess: () => {
            checkPushEnabledAndSet();
            setValue('on');
            setLoading(false);
        },
    });
    const checkPushEnabledAndSet = () => __awaiter(void 0, void 0, void 0, function* () {
        if ('serviceWorker' in navigator && 'Notification' in window) {
            try {
                navigator.serviceWorker.ready.then((serviceWorkerRegistration) => __awaiter(void 0, void 0, void 0, function* () {
                    const registration = serviceWorkerRegistration;
                    const subscription = yield registration.pushManager.getSubscription();
                    if (!subscription) {
                        setValue('off');
                        return;
                    }
                    const dbSubscription = yield getSubscription(subscription.endpoint, 'trading-pro');
                    if (!dbSubscription) {
                        setValue('off');
                        return;
                    }
                    setValue('on');
                }));
            }
            catch (e) {
                console.error(extractErrorMessage(e));
                addToast({
                    variant: 'error',
                    title: extractErrorMessage(e),
                });
            }
        }
    });
    const registerPush = () => __awaiter(void 0, void 0, void 0, function* () {
        if ('serviceWorker' in navigator && 'Notification' in window) {
            try {
                setLoading(true);
                navigator.serviceWorker.ready.then((serviceWorkerRegistration) => __awaiter(void 0, void 0, void 0, function* () {
                    const registration = serviceWorkerRegistration;
                    const subscription = yield registration.pushManager.getSubscription();
                    if (subscription) {
                        yield deleteSubscription(subscription.endpoint, 'trading-pro');
                        yield subscription.unsubscribe();
                    }
                    const vapidKey = yield getVapidKey();
                    const convertedVapidKey = urlBase64ToUint8Array(vapidKey);
                    const pushSubscription = yield registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidKey,
                    });
                    pushSubcribeMutation.mutate(pushSubscription);
                }));
            }
            catch (e) {
                console.error(extractErrorMessage(e));
                addToast({
                    variant: 'error',
                    title: extractErrorMessage(e),
                });
                setLoading(false);
            }
        }
    });
    const disablePush = () => __awaiter(void 0, void 0, void 0, function* () {
        if ('serviceWorker' in navigator && 'Notification' in window) {
            try {
                setLoading(true);
                navigator.serviceWorker.ready.then((serviceWorkerRegistration) => __awaiter(void 0, void 0, void 0, function* () {
                    const registration = serviceWorkerRegistration;
                    const subscription = yield registration.pushManager.getSubscription();
                    if (subscription) {
                        yield deleteSubscription(subscription.endpoint, 'trading-pro');
                        yield subscription.unsubscribe();
                    }
                    checkPushEnabledAndSet();
                }));
            }
            catch (e) {
                console.error(extractErrorMessage(e));
                addToast({
                    variant: 'error',
                    title: extractErrorMessage(e),
                });
            }
            finally {
                setLoading(false);
            }
        }
    });
    useEffect(() => {
        checkPushEnabledAndSet();
    }, []);
    const onChange = (value) => {
        if (value === 'on') {
            registerPush();
        }
        if (value === 'off') {
            disablePush();
        }
    };
    return (_jsxs("div", Object.assign({ className: styles.container }, { children: [_jsx("h1", Object.assign({ className: styles.title }, { children: _t('settings.push-notifications') })), _jsx("p", Object.assign({ className: styles.description }, { children: _t('settings.push-description') })), loading && _jsx(LoadingDots, {}), !loading && (_jsx(ToggleGroup, { value: value, onValueChange: onChange, flexible: true, items: [
                    { label: _t('main.on'), value: 'on' },
                    { label: _t('main.off'), value: 'off' },
                ], variant: 'primary' }))] })));
};
export default PushToggle;
