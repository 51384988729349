var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { END, eventChannel } from 'redux-saga';
import { all, call, put, race, take } from 'redux-saga/effects';
import '../utils/safari';
import { apiActions, apiDisconnected, apiError, handleApiListenerConnected, LOGGED_OFF, LOGIN_REJECT, RESET_ALL, SOCKET_CLOSED, SOCKET_ERROR, socketClosed, socketError, } from '../actions';
import { getConfig } from '../utils/config';
import { ApiMessages } from '../ApiMessages';
import { ApiResponseHandlers } from '../ApiResponseHandlers';
import { incomingMessageLogger, outgoingMessageLogger } from '../utils/logger';
import { ProtoIdentifiers } from '../ProtoIdentifiers';
var EmitEventTypes;
(function (EmitEventTypes) {
    EmitEventTypes["MESSAGE"] = "MESSAGE";
    EmitEventTypes["ERROR"] = "ERROR";
    EmitEventTypes["DISCONNECT"] = "DISCONNECT";
})(EmitEventTypes || (EmitEventTypes = {}));
const connect = () => {
    const appConfig = getConfig();
    if (!appConfig) {
        throw new Error('Config must be set before call to listen()');
    }
    const webSocketUrl = appConfig.feedApi.url;
    console.log('Connecting to: ', webSocketUrl);
    const socket = new WebSocket(webSocketUrl);
    return new Promise((resolve, reject) => {
        socket.onopen = () => {
            resolve(socket);
        };
        socket.onerror = (error) => {
            reject(error);
        };
    });
};
function parseProtobufMessage(data) {
    return __awaiter(this, void 0, void 0, function* () {
        const buffer = yield data.arrayBuffer();
        const identifier = new Uint8Array(buffer.slice(0, 1))[0];
        const message = new Uint8Array(buffer.slice(1));
        const protoIdentifier = ProtoIdentifiers[identifier];
        if (protoIdentifier) {
            const { messageType, proto } = protoIdentifier;
            return Object.assign({ messageType }, proto.decode(message));
        }
        else {
            throw Error(`No Protobuf definition found for identifier: ${identifier}`);
        }
    });
}
function watchMessages(socket) {
    return eventChannel((emit) => {
        socket.onmessage = (event) => __awaiter(this, void 0, void 0, function* () {
            let msg;
            if (!event.data.arrayBuffer) {
                msg = JSON.parse(event.data);
            }
            else {
                msg = yield parseProtobufMessage(event.data);
            }
            emit({
                type: EmitEventTypes.MESSAGE,
                message: msg,
            });
        });
        socket.onerror = (event) => {
            emit({
                type: EmitEventTypes.ERROR,
                message: event,
            });
            console.log('API connection error', event);
            emit(END);
        };
        socket.onclose = (event) => {
            if (event.wasClean) {
                emit({
                    type: EmitEventTypes.DISCONNECT,
                    payload: {
                        event,
                    },
                });
                console.log(`API connection closed cleanly`, event);
            }
            else {
                emit({
                    type: EmitEventTypes.ERROR,
                    payload: {
                        event,
                    },
                });
                console.log(`API connection closed abnormally`, event);
            }
        };
        return () => {
            socket.close();
            // .then(() => console.log('closing socket... logout'));
        };
    });
}
function* listenerTask(socketChannel) {
    var _a, _b;
    while (true) {
        // @ts-ignore
        const event = yield take(socketChannel);
        const { type, message, payload } = event;
        if (type === EmitEventTypes.MESSAGE) {
            const { messageType } = message, data = __rest(message, ["messageType"]);
            if ((messageType !== 'Quote' || messageType !== 'QuotesBatch') && !!incomingMessageLogger) {
                incomingMessageLogger(message);
            }
            // @ts-ignore
            if (!!ApiResponseHandlers[messageType]) {
                // @ts-ignore
                const action = ApiResponseHandlers[messageType](data);
                if (action === null || action === void 0 ? void 0 : action.type) {
                    yield put(action);
                }
            }
            else {
                console.error(`Cannot handle response for "${messageType}". No handlers are defined for this type.`);
            }
        }
        else if (type === EmitEventTypes.DISCONNECT) {
            yield put(socketClosed());
        }
        else if (type === EmitEventTypes.ERROR) {
            yield put(socketError((_a = payload === null || payload === void 0 ? void 0 : payload.event) === null || _a === void 0 ? void 0 : _a.code, (_b = payload === null || payload === void 0 ? void 0 : payload.event) === null || _b === void 0 ? void 0 : _b.reason));
        }
    }
}
/*  User Created Message (e.g. dispatch({ type: 'EXE_TASK', taskid: 5 })) sent to ws server  */
function* executeTask(socket, login) {
    while (true) {
        // @ts-ignore
        const data = yield take(apiActions.SEND);
        if (!!outgoingMessageLogger) {
            outgoingMessageLogger(data.payload);
        }
        socket.send(JSON.stringify(data.payload));
    }
}
export default function* apiListenerSaga() {
    while (true) {
        // @ts-ignore
        const login = yield take(apiActions.LOGIN);
        try {
            // @ts-ignore
            const socket = yield call(connect);
            yield put(handleApiListenerConnected());
            // @ts-ignore
            const socketChannel = yield call(watchMessages, socket);
            socket.send(JSON.stringify(ApiMessages.requestLogin(login.payload.token, login.payload.version)));
            const { cancel } = yield race({
                task: all([call(executeTask, socket, !!login && login.payload), call(listenerTask, socketChannel)]),
                cancel: take([LOGIN_REJECT, RESET_ALL, SOCKET_CLOSED, SOCKET_ERROR, LOGGED_OFF]),
            });
            if (cancel) {
                socketChannel.close();
                yield put(apiDisconnected());
            }
        }
        catch (error) {
            console.log('Error connecting to WebSocket: ', error);
            yield put(apiError(error.toString()));
        }
    }
}
