import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiActions, apiDisconnected, AUTO_LOGIN_REQUESTED, disableAutoLogin, displayError, enableAutoLogin, fetchMessagesUnreadCount, getTrendQuotes, INSTRUMENTS_UPDATED, LOGIN_REJECT, LOGIN_REQUESTED, LOGIN_SUCCESS, loginReject as loginRejectAction, LOGOFF, PRIME_QUOTES_SET, RECEIVED_TRADING_OPTIONS, REFRESH_TOKEN_AND_LOGIN, refreshTokenAndLogin, REQUEST_OAUTH_TOKENS, requestCurrentCompetition, requestOpenPositionsList, RECEIVED_MARGIN_CALL_LEVEL, setLoginStateForAutoLogin, setOAuthTokens, userLoginReal, userSaveLanguage, setConnectionReady, INSTRUMENTS_SET_ACTIVE, receiveInstruments, REQUEST_INSTRUMENTS, requestInstruments, requestGreyLabelLogo, showLoading, loggedOff, } from '../actions';
import { getAllInstruments, login } from '../services/crmService';
import { getConfig } from '../utils/config';
import { getOAuthTokensWithCode, getUser, logout } from '../services/oAuthService';
import { supportsBannersSelector, supportsCompetitionsSelector, supportsMessagesSelector } from '../selectors';
import Analytics from '../utils/Analytics';
function* waitForConnectionReady() {
    const connectionReadySelector = (state) => state.connection.ready;
    // @ts-ignore
    if (yield select(connectionReadySelector))
        return;
    while (true) {
        yield take([
            INSTRUMENTS_UPDATED,
            PRIME_QUOTES_SET,
            RECEIVED_TRADING_OPTIONS,
            RECEIVED_MARGIN_CALL_LEVEL,
            INSTRUMENTS_SET_ACTIVE,
        ]);
        const connectionState = yield select((state) => state.connection);
        if (connectionState.instrumentsReceived &&
            connectionState.accountInfoReceived &&
            connectionState.primeQuotesReceived &&
            connectionState.tradingOptionsReceived &&
            connectionState.marginCallLevelReceived) {
            yield put(setConnectionReady(true));
            return;
        }
    }
}
function* fetchAllInstruments() {
    try {
        const { instruments, timezone, defaultInstrument } = yield call(getAllInstruments);
        yield put(receiveInstruments(instruments, timezone, defaultInstrument));
    }
    catch (error) {
        yield put(displayError('Failed fetching instruments', error.message));
    }
}
function* loginSuccess() {
    yield put(setLoginStateForAutoLogin(true));
    yield put(enableAutoLogin());
    yield put(requestGreyLabelLogo());
    yield put(requestInstruments());
    yield call(waitForConnectionReady);
    yield put(requestOpenPositionsList());
    const supportsCompetitions = yield select(supportsCompetitionsSelector);
    const supportsMessages = yield select(supportsMessagesSelector);
    const supportsBanners = yield select(supportsBannersSelector);
    if (supportsCompetitions) {
        yield put(requestCurrentCompetition());
    }
    if (supportsMessages) {
        yield put(fetchMessagesUnreadCount());
    }
    yield put(getTrendQuotes());
}
function* loginReject(action) {
    const { message } = action.payload;
    // @ts-ignore
    yield put(apiDisconnected());
    // @ts-ignore
    yield put(displayError('LOGIN_REJECT', message));
}
function* loginRequest(action) {
    try {
        const version = yield select((state) => state.releaseVersion);
        const token = yield select((state) => state.user.token);
        const { user, supportedFeatures, wallet } = yield call(getUser);
        if (user.communicationLanguage) {
            yield put(userSaveLanguage(user.communicationLanguage, true));
        }
        yield put({
            type: apiActions.LOGIN,
            payload: {
                token,
                user,
                wallet,
                version,
                supportedFeatures,
            },
        });
    }
    catch (e) {
        const { response } = e;
        if (response && response.data && response.data.error) {
            yield put(loginRejectAction(response.data.error.reason));
        }
        else {
            yield put(loginRejectAction(e.message));
        }
    }
}
function* autoLogin(action) {
    var _a;
    const version = yield select((state) => state.releaseVersion);
    const { token, refreshToken } = yield select((state) => state.user);
    if (!!token) {
        try {
            const { user, supportedFeatures, wallet } = yield call(getUser);
            // handle potentially updated token from apiClient interceptor
            const { token: freshToken } = yield select((state) => state.user);
            if (user.communicationLanguage) {
                yield put(userSaveLanguage(user.communicationLanguage, true));
            }
            yield put({
                type: apiActions.LOGIN,
                payload: {
                    token: freshToken,
                    user,
                    wallet,
                    version,
                    supportedFeatures,
                },
            });
        }
        catch (e) {
            const { response } = e;
            if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.error) {
                yield put(loginRejectAction(response.data.error.reason));
            }
            else {
                yield put(loginRejectAction(e.message));
            }
        }
    }
    else if (!!refreshToken) {
        yield put(refreshTokenAndLogin());
    }
}
function* handleLogoff(action) {
    const { userRequested, withOAuth } = action.payload;
    const refreshToken = yield select((state) => state.user.refreshToken);
    let redirectUri = null;
    if (withOAuth) {
        try {
            redirectUri = yield call(logout, refreshToken, `${window.location.origin}/login`);
        }
        catch (e) {
            console.error('Failed to log out', e);
        }
    }
    if (userRequested) {
        yield put(disableAutoLogin());
    }
    yield put(loggedOff());
    Analytics.resetUserData();
    if (redirectUri !== null) {
        // shows loader to prevent login screen from flashing
        yield put(showLoading());
        // @ts-expect-error
        window.location = redirectUri;
    }
}
function* refreshTokenAndLoginSaga() {
    // TODO check if this is needed anymore, probably not
    const { refreshToken } = yield select((state) => state.user);
    const version = yield select((state) => state.releaseVersion);
    if (refreshToken) {
        try {
            const { token, user } = yield call(login, { refreshToken });
            if (user.communicationLanguage) {
                yield put(userSaveLanguage(user.communicationLanguage, true));
            }
            yield put({
                type: apiActions.LOGIN,
                payload: {
                    token,
                    user,
                    version,
                },
            });
        }
        catch (e) {
            const { response } = e;
            if (response && response.data && response.data.error) {
                yield put(loginRejectAction(response.data.error.reason));
            }
            else {
                yield put(loginRejectAction(e.message));
            }
        }
    }
    else {
        yield put(loginRejectAction());
    }
}
function* requestOAuthTokensSaga(action) {
    const { code, redirectUri } = action.payload;
    const { clientId, clientSecret } = getConfig().oAuth;
    const grantType = 'authorization_code';
    if (clientId && clientSecret) {
        try {
            const { access_token, refresh_token } = yield call(getOAuthTokensWithCode, code, clientId, clientSecret, redirectUri, grantType);
            yield put(setOAuthTokens(access_token, refresh_token));
            yield put(userLoginReal());
        }
        catch (e) {
            yield put(loginRejectAction(e.message));
        }
    }
    else {
        console.error('clientId & clientSecret must be set in config');
    }
}
function* loginSuccessSaga(action) {
    const id = yield select((state) => state.user.id);
    if (id != null) {
        Analytics.identifyUser(id);
    }
}
function* loginSaga() {
    yield takeLatest(LOGIN_SUCCESS, loginSuccess);
    yield takeLatest(LOGIN_REJECT, loginReject);
    yield takeEvery(LOGIN_REQUESTED, loginRequest);
    yield takeEvery(AUTO_LOGIN_REQUESTED, autoLogin);
    yield takeLatest(LOGOFF, handleLogoff);
    yield takeLatest(REFRESH_TOKEN_AND_LOGIN, refreshTokenAndLoginSaga);
    yield takeLatest(REQUEST_OAUTH_TOKENS, requestOAuthTokensSaga);
    yield takeLatest(LOGIN_SUCCESS, loginSuccessSaga);
    yield takeLatest(REQUEST_INSTRUMENTS, fetchAllInstruments);
}
export default loginSaga;
